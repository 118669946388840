import React from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as Loading } from '../../../assets/images/loading.svg';

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  backgroundColor: '#F5F6F7',
});

type Props = {
  size?: string;
};

const AppLoading: React.FC<Props> = ({ size = '80px' }: Props) => {
  return (
    <Wrapper>
      <Loading height={size} width={size} />
    </Wrapper>
  );
};

AppLoading.defaultProps = {
  size: '80px',
};

export default React.memo(AppLoading);
