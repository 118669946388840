export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type IUser = {
  id: Scalars['ID'];
  avatarUrl?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  role?: Maybe<Role>;
  timezone?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  updated?: Maybe<Scalars['Date']>;
};


export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
  Others = 'OTHERS'
}

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  /** The title of the role. */
  name: Scalars['String'];
  /** The slug of the role. For submitting form. */
  slug: Scalars['String'];
};

export type SessionIssue = {
  __typename?: 'SessionIssue';
  id: Scalars['ID'];
  resolvedBySystem: Scalars['Boolean'];
  status: SessionIssueStatus;
  type: SessionIssueType;
  reason?: Maybe<SessionIssueReason>;
  action?: Maybe<SessionIssueAction>;
  typeDetails?: Maybe<Scalars['String']>;
  reasonDetails?: Maybe<Scalars['String']>;
  reporterMessage?: Maybe<Scalars['String']>;
  resolverMessage?: Maybe<Scalars['String']>;
  resolvedAt?: Maybe<Scalars['Date']>;
  created: Scalars['Date'];
  updated: Scalars['Date'];
  reporter?: Maybe<IUser>;
  resolvedUser?: Maybe<IUser>;
  session: Session;
};

export enum SessionIssueStatus {
  Open = 'OPEN',
  Resolved = 'RESOLVED'
}

export enum SessionIssueType {
  StudentWasAbsent = 'STUDENT_WAS_ABSENT',
  StudentWasLate = 'STUDENT_WAS_LATE',
  StudentLeftEarly = 'STUDENT_LEFT_EARLY',
  TutorWasAbsent = 'TUTOR_WAS_ABSENT',
  TutorLeftEarly = 'TUTOR_LEFT_EARLY',
  StudentRelatedTechnicalProblem = 'STUDENT_RELATED_TECHNICAL_PROBLEM',
  TutorRelatedTechnicalProblem = 'TUTOR_RELATED_TECHNICAL_PROBLEM',
  SiteRelatedTechnicalProblem = 'SITE_RELATED_TECHNICAL_PROBLEM',
  TutorCanceled = 'TUTOR_CANCELED',
  Other = 'OTHER'
}

export enum SessionIssueReason {
  SessionWasDelivered = 'SESSION_WAS_DELIVERED',
  AskedByStudent = 'ASKED_BY_STUDENT',
  UnstableConnection = 'UNSTABLE_CONNECTION',
  Blackout = 'BLACKOUT',
  ComputerProblem = 'COMPUTER_PROBLEM',
  Sickness = 'SICKNESS',
  SicknessFamily = 'SICKNESS_FAMILY',
  OtherTutorRelated = 'OTHER_TUTOR_RELATED',
  Other = 'OTHER'
}

export enum SessionIssueAction {
  MarkAsCanceled = 'MARK_AS_CANCELED',
  MarkAsCompleted = 'MARK_AS_COMPLETED',
  HalfRefund = 'HALF_REFUND',
  FullRefund = 'FULL_REFUND'
}

export type StudentPriorInfo = {
  __typename?: 'StudentPriorInfo';
  id: Scalars['ID'];
  priorExperience: PriorExperience;
  priorExperienceNote?: Maybe<Scalars['String']>;
};

export enum PriorExperience {
  None = 'NONE',
  LtSixMonth = 'LT_SIX_MONTH',
  LtOneYear = 'LT_ONE_YEAR',
  MtOneYear = 'MT_ONE_YEAR'
}

export type SessionSummary = {
  __typename?: 'SessionSummary';
  id: Scalars['ID'];
  coveredPart?: Maybe<Scalars['String']>;
  commentGeneral: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  isDraft: Scalars['Boolean'];
  emailSent: Scalars['Boolean'];
  coveredTopics: Array<Scalars['String']>;
  submittedAt: Scalars['Date'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  session?: Maybe<Session>;
  sessionsLessons: Array<SessionsLesson>;
};

export type SessionsLesson = {
  __typename?: 'SessionsLesson';
  id: Scalars['ID'];
  completed: Scalars['Boolean'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  sessionsConcepts: Array<SessionsConcept>;
  session?: Maybe<Session>;
  sessionSummary?: Maybe<SessionSummary>;
  lesson?: Maybe<Lesson>;
};

export type SessionsConcept = {
  __typename?: 'SessionsConcept';
  id: Scalars['ID'];
  completed: Scalars['Boolean'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  sessionsLesson: SessionsLesson;
  concept?: Maybe<Concept>;
};

export type StudentsConcept = {
  __typename?: 'StudentsConcept';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  student?: Maybe<Student>;
  concept?: Maybe<Concept>;
};

export type Concept = {
  __typename?: 'Concept';
  id: Scalars['ID'];
  /** The title of the concept. */
  title: Scalars['String'];
  /** The slug of the concept. */
  slug: Scalars['String'];
  sortOrder: Scalars['Float'];
  type: ConceptType;
  status: ContentStatus;
  visibility: ContentVisibility;
  lesson: Lesson;
  sessionsConcepts: Array<SessionsConcept>;
  studentsConcepts: Array<StudentsConcept>;
};

export enum ConceptType {
  Text = 'TEXT',
  Video = 'VIDEO',
  Activity = 'ACTIVITY',
  BonusActivity = 'BONUS_ACTIVITY'
}

export enum ContentStatus {
  Published = 'PUBLISHED',
  Draft = 'DRAFT'
}

export enum ContentVisibility {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type StudentsLesson = {
  __typename?: 'StudentsLesson';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  student?: Maybe<Student>;
  lesson?: Maybe<Lesson>;
};

export type Lesson = {
  __typename?: 'Lesson';
  id: Scalars['ID'];
  /** The title of the lesson. */
  title: Scalars['String'];
  /** The slug of the lesson. */
  slug: Scalars['String'];
  sortOrder: Scalars['Float'];
  type: LessonType;
  status: ContentStatus;
  visibility: ContentVisibility;
  concepts: Array<Concept>;
  course: Course;
  sessionsLessons: Array<SessionsLesson>;
  studentsLessons: Array<StudentsLesson>;
};

export enum LessonType {
  Lecture = 'LECTURE',
  Project = 'PROJECT'
}

export type StudentsCourse = {
  __typename?: 'StudentsCourse';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  student?: Maybe<Student>;
  course?: Maybe<Course>;
};

export type Placement = {
  __typename?: 'Placement';
  id: Scalars['ID'];
  internalNote: Scalars['String'];
  student: Student;
  session: Session;
  recommendedCourse: Course;
};

export type TutorSetting = {
  __typename?: 'TutorSetting';
  id: Scalars['ID'];
  canOfferTrial: Scalars['Boolean'];
  primaryShift?: Maybe<ShiftDivision>;
  status: TutorStatus;
  paypalEmail?: Maybe<Scalars['String']>;
  internalNote?: Maybe<Scalars['String']>;
};

export enum ShiftDivision {
  Evening = 'EVENING',
  Midnight = 'MIDNIGHT',
  Afternoon = 'AFTERNOON',
  Morning = 'MORNING'
}

export enum TutorStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Banned = 'BANNED',
  Dismissed = 'DISMISSED',
  Resigned = 'RESIGNED'
}

export type Shift = {
  __typename?: 'Shift';
  id: Scalars['ID'];
  durationStart: Scalars['Date'];
  durationEnd?: Maybe<Scalars['Date']>;
  forTrial: Scalars['Boolean'];
  slots: Array<Scalars['Float']>;
  tutor: IUser;
};

export type HourlyRate = {
  __typename?: 'HourlyRate';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  baseRate: Scalars['Float'];
  specialAdjustment: Scalars['Float'];
  graveyardApplied: Scalars['Boolean'];
  veteranApplied: Scalars['Boolean'];
  undergradApplied: Scalars['Boolean'];
  tutor: Tutor;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  id: Scalars['ID'];
  /** The name of the notification type. */
  name: Scalars['String'];
  /** The slug of the notification type. */
  slug: Scalars['String'];
  notificationSetting: NotificationSetting;
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  id: Scalars['ID'];
  onMobile: Scalars['Boolean'];
  onDesktop: Scalars['Boolean'];
  byEmail: Scalars['Boolean'];
  notificationType: NotificationType;
  user: IUser;
};

export type TutoringSettlementRequest = {
  __typename?: 'TutoringSettlementRequest';
  id: Scalars['ID'];
  status: TutoringSettlementRequestStatus;
  reason: TutoringSettlementRequestReason;
  requestReasonDetails?: Maybe<Scalars['String']>;
  closedReasonDetails?: Maybe<Scalars['String']>;
  numNeedsToMatch: Scalars['Float'];
  matchedSlots: Array<Scalars['Float']>;
  created: Scalars['Date'];
  updated: Scalars['Date'];
  student: Student;
  settlements: Array<TutoringSettlement>;
};

export enum TutoringSettlementRequestStatus {
  Requested = 'REQUESTED',
  Assigned = 'ASSIGNED',
  Canceled = 'CANCELED',
  Closed = 'CLOSED'
}

export enum TutoringSettlementRequestReason {
  NewSubscription = 'NEW_SUBSCRIPTION',
  NewCourse = 'NEW_COURSE',
  ScheduleMismatch = 'SCHEDULE_MISMATCH',
  TutorMismatch = 'TUTOR_MISMATCH',
  UseCredits = 'USE_CREDITS',
  Other = 'OTHER'
}

export type TutoringSettlement = {
  __typename?: 'TutoringSettlement';
  id: Scalars['ID'];
  periodFrom: Scalars['Date'];
  periodTo?: Maybe<Scalars['Date']>;
  status: TutoringSettlementStatus;
  slot: Scalars['Float'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  student: Student;
  tutor: Tutor;
  request: TutoringSettlementRequest;
  conversationId: Scalars['String'];
};

export enum TutoringSettlementStatus {
  Active = 'ACTIVE',
  Changed = 'CHANGED',
  Completed = 'COMPLETED'
}

export type Tutor = IUser & {
  __typename?: 'Tutor';
  id: Scalars['ID'];
  avatarUrl?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  role?: Maybe<Role>;
  timezone?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  updated?: Maybe<Scalars['Date']>;
  nickname?: Maybe<Scalars['String']>;
  zoomUrl?: Maybe<Scalars['String']>;
  skypeId?: Maybe<Scalars['String']>;
  students?: Maybe<Array<Student>>;
  tutorSetting?: Maybe<TutorSetting>;
  regularShifts?: Maybe<Array<Shift>>;
  trialShifts?: Maybe<Array<Shift>>;
  hourlyRate?: Maybe<HourlyRate>;
  unseenMessageCount: Scalars['Float'];
  notificationSettings?: Maybe<Array<NotificationSetting>>;
  teachableCourses: Array<TeachableCourse>;
  myStudents: Array<Student>;
  activeMatchs?: Maybe<Array<TutoringSettlement>>;
  availableSlotsForMatch: Array<Scalars['Float']>;
};

export type TeachableCourse = {
  __typename?: 'TeachableCourse';
  id: Scalars['ID'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  tutor?: Maybe<Tutor>;
  course?: Maybe<Course>;
};

export type Course = {
  __typename?: 'Course';
  id: Scalars['ID'];
  /** The title of the course. */
  title: Scalars['String'];
  /** The slug of the course. */
  slug: Scalars['String'];
  lessons: Array<Lesson>;
  studentsCourses: Array<StudentsCourse>;
  teachableCourses: Array<TeachableCourse>;
};

export type StudentProfile = {
  __typename?: 'StudentProfile';
  id: Scalars['ID'];
  status: StudentStatus;
  credits: Scalars['Float'];
  counselingCredits: Scalars['Float'];
  assignedCourseId: Scalars['String'];
  assignedCourse?: Maybe<Course>;
};

export enum StudentStatus {
  BeforeInvite = 'BEFORE_INVITE',
  BeforePayTrial = 'BEFORE_PAY_TRIAL',
  BeforeRequestTrial = 'BEFORE_REQUEST_TRIAL',
  BeforeTrial = 'BEFORE_TRIAL',
  BeforePlacement = 'BEFORE_PLACEMENT',
  BeforeRecommendTutor = 'BEFORE_RECOMMEND_TUTOR',
  BeforeSubscribe = 'BEFORE_SUBSCRIBE',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  AccountDeactivated = 'ACCOUNT_DEACTIVATED'
}

export type StudentOnboarding = {
  __typename?: 'StudentOnboarding';
  id: Scalars['ID'];
  studentId: Scalars['String'];
  setTimezone: OnboardingTaskStatus;
  registerProfile: OnboardingTaskStatus;
  requestTrial: OnboardingTaskStatus;
  signupToCodeorg: OnboardingTaskStatus;
  signupToScratch: OnboardingTaskStatus;
  completed: Scalars['Boolean'];
};

export enum OnboardingTaskStatus {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Skipped = 'SKIPPED',
  Completed = 'COMPLETED'
}

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  nameOnProcessor: Scalars['String'];
  plans: Array<Plan>;
};

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  nameOnProcessor: Scalars['String'];
  paypalId: Scalars['String'];
  active: Scalars['Boolean'];
  amount: Scalars['Float'];
  currency: Scalars['String'];
  credits: Scalars['Float'];
  product: Product;
  subscriptions: Array<PlanSubscription>;
};

export type PlanSubscription = {
  __typename?: 'PlanSubscription';
  id: Scalars['ID'];
  paypalId: Scalars['String'];
  paypalPayerId: Scalars['String'];
  outstandingBalance: Scalars['String'];
  nextBillingDate: Scalars['Date'];
  startTime: Scalars['Date'];
  currentPeriodStart: Scalars['Date'];
  currentPeriodEnd: Scalars['Date'];
  status: SubscriptionStatus;
  created: Scalars['Date'];
  updated: Scalars['Date'];
  student: IUser;
  plan: Plan;
};

export enum SubscriptionStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Canceled = 'CANCELED'
}

export type StudentAvailability = {
  __typename?: 'StudentAvailability';
  id: Scalars['ID'];
  primarySlots: Array<Scalars['Float']>;
  secondarySlots: Array<Scalars['Float']>;
  student: IUser;
};

export type Student = IUser & {
  __typename?: 'Student';
  id: Scalars['ID'];
  avatarUrl?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  role?: Maybe<Role>;
  timezone: Scalars['String'];
  created?: Maybe<Scalars['Date']>;
  updated?: Maybe<Scalars['Date']>;
  dialCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified: Scalars['Boolean'];
  pname: Scalars['String'];
  unseenMessageCount: Scalars['Float'];
  priorInfo: StudentPriorInfo;
  profile: StudentProfile;
  status: Scalars['String'];
  studentsCourses: Array<StudentsCourse>;
  studentsLessons: Array<StudentsLesson>;
  studentsConcepts: Array<StudentsConcept>;
  placement?: Maybe<Placement>;
  studentAvailability?: Maybe<StudentAvailability>;
  currentSubscription?: Maybe<PlanSubscription>;
  onboarding?: Maybe<StudentOnboarding>;
  notificationSettings?: Maybe<Array<NotificationSetting>>;
  recentSession?: Maybe<Session>;
  matchRequest?: Maybe<TutoringSettlementRequest>;
  activeMatchs?: Maybe<Array<TutoringSettlement>>;
};

export type Homework = {
  __typename?: 'Homework';
  id: Scalars['ID'];
  dueDate: Scalars['Date'];
  title: Scalars['String'];
  instructions: Scalars['String'];
  messageOnCompletion: Scalars['String'];
  completed: Scalars['Boolean'];
  completedAt: Scalars['Date'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  student: Student;
  tutor: Tutor;
  session: Session;
};

export type SessionReview = {
  __typename?: 'SessionReview';
  id: Scalars['ID'];
  commentOnTutor?: Maybe<Scalars['String']>;
  commentBack?: Maybe<Scalars['String']>;
  created: Scalars['Date'];
  ratingTotal: Scalars['Float'];
  ratingVideo: Scalars['Float'];
  updated: Scalars['Date'];
  student: Student;
  tutor: Tutor;
  session: Session;
};

export type SessionRequest = {
  __typename?: 'SessionRequest';
  id: Scalars['ID'];
  requesterId: Scalars['String'];
  status: SessionRequestStatus;
  type: SessionRequestType;
  reason: SessionIssueReason;
  requestMessage?: Maybe<Scalars['String']>;
  responseMessage?: Maybe<Scalars['String']>;
  respondedAt?: Maybe<Scalars['Date']>;
  dueDate?: Maybe<Scalars['Date']>;
  durationStart: Scalars['Date'];
  durationEnd: Scalars['Date'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  requester: IUser;
  student?: Maybe<IUser>;
  tutor?: Maybe<IUser>;
  session?: Maybe<Session>;
  createdRegularSession: Session;
};

export enum SessionRequestStatus {
  Requested = 'REQUESTED',
  Declined = 'DECLINED',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Accepted = 'ACCEPTED'
}

export enum SessionRequestType {
  Regular = 'REGULAR',
  Reschedule = 'RESCHEDULE'
}

export type Session = {
  __typename?: 'Session';
  id: Scalars['ID'];
  isTrial: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  isReschedule: Scalars['Boolean'];
  isRequested: Scalars['Boolean'];
  slot: Scalars['Float'];
  durationStart: Scalars['Date'];
  durationEnd: Scalars['Date'];
  status: SessionStatus;
  creditsIssued: Scalars['Float'];
  creditsOnTutor: Scalars['Float'];
  creditsOnCg: Scalars['Float'];
  creditsUsed: Scalars['Float'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  student: Student;
  tutor: Tutor;
  sessionIssue?: Maybe<SessionIssue>;
  sessionSummary?: Maybe<SessionSummary>;
  sessionReview?: Maybe<SessionReview>;
  placement?: Maybe<Placement>;
  sessionsLessons: Array<SessionsLesson>;
  attendance?: Maybe<Scalars['Boolean']>;
  homework?: Maybe<Homework>;
  creditsOnStudent: Scalars['Float'];
  sessionRequests: Array<SessionRequest>;
  baseSessionRequest: SessionRequest;
  needsSummary: Scalars['Boolean'];
  summaryReady: Scalars['Boolean'];
  conversationId: Scalars['String'];
};

export enum SessionStatus {
  Scheduled = 'SCHEDULED',
  CanceledUnverified = 'CANCELED_UNVERIFIED',
  CanceledByStudent = 'CANCELED_BY_STUDENT',
  CanceledByTutor = 'CANCELED_BY_TUTOR',
  Completed = 'COMPLETED',
  Incomplete = 'INCOMPLETE',
  Rescheduling = 'RESCHEDULING',
  Rescheduled = 'RESCHEDULED'
}

export type ConversationMember = {
  __typename?: 'ConversationMember';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  lastViewedAt: Scalars['Date'];
  msgCount: Scalars['Float'];
  unreadCount: Scalars['Float'];
  name: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  node: Message;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  pageInfo: PageInfo;
  edges: Array<MessageEdge>;
  totalCount: Scalars['Float'];
};

export type Conversation = {
  __typename?: 'Conversation';
  id: Scalars['ID'];
  lastMessageSentAt: Scalars['Date'];
  lastMessage: Message;
  members: Array<ConversationMember>;
  messages: MessageConnection;
};


export type ConversationMessagesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
};

export type MessagePart = {
  __typename?: 'MessagePart';
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  message?: Maybe<Message>;
};


export type MessageRecipient = {
  __typename?: 'MessageRecipient';
  id: Scalars['ID'];
  readAt?: Maybe<Scalars['Date']>;
  isRead: Scalars['Boolean'];
  message?: Maybe<Message>;
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  sentAt: Scalars['Date'];
  senderId?: Maybe<Scalars['String']>;
  sender?: Maybe<IUser>;
  messageParts: Array<MessagePart>;
  messageRecipients: Array<MessageRecipient>;
  status: MessageStatus;
};

export enum MessageStatus {
  Sending = 'SENDING',
  Sent = 'SENT',
  Unread = 'UNREAD',
  SomeRead = 'SOME_READ',
  Read = 'READ'
}

export type UserEdge = {
  __typename?: 'UserEdge';
  node: IUser;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  pageInfo: PageInfo;
  edges: Array<UserEdge>;
  totalCount: Scalars['Float'];
};

export type UserError = DisplayableError & {
  __typename?: 'UserError';
  field: Array<Scalars['String']>;
  message: Scalars['String'];
};

export type DisplayableError = {
  field: Array<Scalars['String']>;
  message: Scalars['String'];
};

/** Only returnes userErrors as mutaion result */
export type ActionResult = {
  __typename?: 'ActionResult';
  userErrors?: Maybe<Array<UserError>>;
};

export type AuthResult = {
  __typename?: 'AuthResult';
  userErrors?: Maybe<Array<UserError>>;
  viewer?: Maybe<IUser>;
  customToken?: Maybe<Scalars['String']>;
};

export type StudentResultOnAdmin = {
  __typename?: 'StudentResultOnAdmin';
  userErrors?: Maybe<Array<UserError>>;
  student?: Maybe<Student>;
};

export type UpdateTutorAsAdminResult = {
  __typename?: 'UpdateTutorAsAdminResult';
  userErrors?: Maybe<Array<UserError>>;
  tutor?: Maybe<Tutor>;
};

export type AvailableStartDateForMatchResult = {
  __typename?: 'AvailableStartDateForMatchResult';
  time?: Maybe<Scalars['Date']>;
};

export type Admin = IUser & {
  __typename?: 'Admin';
  id: Scalars['ID'];
  avatarUrl?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  role?: Maybe<Role>;
  timezone?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  updated?: Maybe<Scalars['Date']>;
};

export type Guest = IUser & {
  __typename?: 'Guest';
  id: Scalars['ID'];
  avatarUrl?: Maybe<Scalars['String']>;
  photoURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  role?: Maybe<Role>;
  timezone?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  updated?: Maybe<Scalars['Date']>;
};

export type SessionEdge = {
  __typename?: 'SessionEdge';
  node: Session;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type SessionConnection = {
  __typename?: 'SessionConnection';
  pageInfo: PageInfo;
  edges: Array<SessionEdge>;
  totalCount: Scalars['Float'];
};

export type SessionResult = {
  __typename?: 'SessionResult';
  userErrors?: Maybe<Array<UserError>>;
  session?: Maybe<Session>;
};

export type SendOtpResult = {
  __typename?: 'SendOTPResult';
  userErrors?: Maybe<Array<UserError>>;
  verificationId?: Maybe<Scalars['String']>;
};

export type VerifyOtpResult = {
  __typename?: 'VerifyOTPResult';
  userErrors?: Maybe<Array<UserError>>;
};

export type TutoringSettlementEdge = {
  __typename?: 'TutoringSettlementEdge';
  node: TutoringSettlement;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type TutoringSettlementConnection = {
  __typename?: 'TutoringSettlementConnection';
  pageInfo: PageInfo;
  edges: Array<TutoringSettlementEdge>;
  totalCount: Scalars['Float'];
};

export type TutoringSettlementResult = {
  __typename?: 'TutoringSettlementResult';
  userErrors?: Maybe<Array<UserError>>;
  edge?: Maybe<TutoringSettlementEdge>;
};

export type CreateShiftResult = {
  __typename?: 'CreateShiftResult';
  userErrors?: Maybe<Array<UserError>>;
  shift?: Maybe<Shift>;
};

export type SessionIssueEdge = {
  __typename?: 'SessionIssueEdge';
  node: SessionIssue;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type SessionIssueConnection = {
  __typename?: 'SessionIssueConnection';
  pageInfo: PageInfo;
  edges: Array<SessionIssueEdge>;
  totalCount: Scalars['Float'];
};

export type SessionIssueResult = {
  __typename?: 'SessionIssueResult';
  userErrors?: Maybe<Array<UserError>>;
  edge?: Maybe<SessionIssueEdge>;
};

export type SlotStatus = {
  __typename?: 'SlotStatus';
  startAt?: Maybe<Scalars['Date']>;
  type: SlotStatusType;
  utcSlot: Scalars['Float'];
  deletion: Scalars['Boolean'];
  count?: Maybe<Scalars['Float']>;
};

export enum SlotStatusType {
  Regular = 'REGULAR',
  Reserved = 'RESERVED',
  Added = 'ADDED',
  Removed = 'REMOVED',
  Open = 'OPEN',
  Empty = 'EMPTY',
  Unavailable = 'UNAVAILABLE'
}

export type HomeworkResult = {
  __typename?: 'HomeworkResult';
  userErrors?: Maybe<Array<UserError>>;
  homework?: Maybe<Homework>;
};

export type HomeworkEdge = {
  __typename?: 'HomeworkEdge';
  node: Homework;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type HomeworkConnection = {
  __typename?: 'HomeworkConnection';
  pageInfo: PageInfo;
  edges: Array<HomeworkEdge>;
  totalCount: Scalars['Float'];
};

export type SessionSummaryResult = {
  __typename?: 'SessionSummaryResult';
  userErrors?: Maybe<Array<UserError>>;
  sessionSummary?: Maybe<SessionSummary>;
};

export type TutoringSettlementRequestEdge = {
  __typename?: 'TutoringSettlementRequestEdge';
  node: TutoringSettlementRequest;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type TutoringSettlementRequestConnection = {
  __typename?: 'TutoringSettlementRequestConnection';
  pageInfo: PageInfo;
  edges: Array<TutoringSettlementRequestEdge>;
  totalCount: Scalars['Float'];
};

export type RequestMatachResult = {
  __typename?: 'RequestMatachResult';
  userErrors?: Maybe<Array<UserError>>;
  matchRequest?: Maybe<TutoringSettlementRequest>;
};

export type StudentAvailabilityResult = {
  __typename?: 'StudentAvailabilityResult';
  userErrors?: Maybe<Array<UserError>>;
  studentAvailability?: Maybe<StudentAvailability>;
};

export type SessionRequestEdge = {
  __typename?: 'SessionRequestEdge';
  node: SessionRequest;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type SessionRequestResult = {
  __typename?: 'SessionRequestResult';
  userErrors?: Maybe<Array<UserError>>;
  edge?: Maybe<SessionRequestEdge>;
  sessionRequest?: Maybe<SessionRequest>;
};

export type SessionRequestConnection = {
  __typename?: 'SessionRequestConnection';
  pageInfo: PageInfo;
  edges: Array<SessionRequestEdge>;
  totalCount: Scalars['Float'];
};

export type SubscriptionEdge = {
  __typename?: 'SubscriptionEdge';
  node: PlanSubscription;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type SubscriptionConnection = {
  __typename?: 'SubscriptionConnection';
  pageInfo: PageInfo;
  edges: Array<SubscriptionEdge>;
  totalCount: Scalars['Float'];
};

export type SubscriptionResult = {
  __typename?: 'SubscriptionResult';
  userErrors?: Maybe<Array<UserError>>;
  subscription?: Maybe<PlanSubscription>;
};

export type PrepareSubscriptionResult = {
  __typename?: 'PrepareSubscriptionResult';
  userErrors?: Maybe<Array<UserError>>;
  paypalSubId?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type Charge = {
  __typename?: 'Charge';
  id: Scalars['ID'];
  paypalId: Scalars['String'];
  currency: Scalars['String'];
  amount: Scalars['Float'];
  refund_amount: Scalars['Float'];
  student: IUser;
};

export type ChargeEdge = {
  __typename?: 'ChargeEdge';
  node: Charge;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type ChargeConnection = {
  __typename?: 'ChargeConnection';
  pageInfo: PageInfo;
  edges: Array<ChargeEdge>;
  totalCount: Scalars['Float'];
};

export type ChargeResult = {
  __typename?: 'ChargeResult';
  userErrors?: Maybe<Array<UserError>>;
  charge?: Maybe<Charge>;
};

export type ConversationEdge = {
  __typename?: 'ConversationEdge';
  node: Conversation;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type ConversationConnection = {
  __typename?: 'ConversationConnection';
  pageInfo: PageInfo;
  edges: Array<ConversationEdge>;
  totalCount: Scalars['Float'];
};

export type MessageAddedResponse = {
  __typename?: 'MessageAddedResponse';
  userId: Scalars['String'];
  conversationId: Scalars['String'];
  message: Message;
};

export type OnMessageReadResponse = {
  __typename?: 'OnMessageReadResponse';
  userId: Scalars['String'];
  conversationId: Scalars['String'];
  messageId: Scalars['String'];
  status: MessageStatus;
};

export type SendMessageResult = {
  __typename?: 'SendMessageResult';
  userErrors?: Maybe<Array<UserError>>;
  newEdge?: Maybe<MessageEdge>;
};

export type CourseEdge = {
  __typename?: 'CourseEdge';
  node: Course;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type CourseConnection = {
  __typename?: 'CourseConnection';
  pageInfo: PageInfo;
  edges: Array<CourseEdge>;
  totalCount: Scalars['Float'];
};

export type CourseResult = {
  __typename?: 'CourseResult';
  userErrors?: Maybe<Array<UserError>>;
  course?: Maybe<Course>;
};

export type UpdateNotificationSettingsResult = {
  __typename?: 'UpdateNotificationSettingsResult';
  userErrors?: Maybe<Array<UserError>>;
  notificationSettings?: Maybe<Array<NotificationSetting>>;
  viewer?: Maybe<IUser>;
};

export type Query = {
  __typename?: 'Query';
  user?: Maybe<IUser>;
  viewer: IUser;
  users?: Maybe<UserConnection>;
  student?: Maybe<Student>;
  tutor?: Maybe<Tutor>;
  availableTutorsToMatchOnSlot?: Maybe<Array<Tutor>>;
  availableStartDateForMatch?: Maybe<AvailableStartDateForMatchResult>;
  session?: Maybe<Session>;
  trialSession?: Maybe<Session>;
  sessions?: Maybe<SessionConnection>;
  tutoringSettlements?: Maybe<TutoringSettlementConnection>;
  matchRequest?: Maybe<TutoringSettlementRequest>;
  tutoringSettlementRequests?: Maybe<TutoringSettlementRequestConnection>;
  shifts?: Maybe<Array<Shift>>;
  sessionIssues?: Maybe<SessionIssueConnection>;
  slotStatuses?: Maybe<Array<SlotStatus>>;
  homeworks?: Maybe<HomeworkConnection>;
  getLastHomeworkOfStudent?: Maybe<Homework>;
  getHomeworkBySession?: Maybe<Homework>;
  sessionSummary?: Maybe<SessionSummary>;
  sessionRequests?: Maybe<SessionRequestConnection>;
  sessionRequest?: Maybe<SessionRequest>;
  studentAvailability?: Maybe<StudentAvailability>;
  plans?: Maybe<Array<Plan>>;
  subscriptions?: Maybe<SubscriptionConnection>;
  charges?: Maybe<ChargeConnection>;
  conversation?: Maybe<Conversation>;
  conversations?: Maybe<ConversationConnection>;
  courses?: Maybe<CourseConnection>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  role?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['Date']>;
  createdBefore?: Maybe<Scalars['Date']>;
  filter?: Maybe<UserFilterSimple>;
};


export type QueryStudentArgs = {
  id: Scalars['String'];
};


export type QueryTutorArgs = {
  id: Scalars['String'];
};


export type QueryAvailableTutorsToMatchOnSlotArgs = {
  input: AvailableTutorsToMatchOnSlotInput;
};


export type QueryAvailableStartDateForMatchArgs = {
  input: AvailableStartDateForMatchInput;
};


export type QuerySessionArgs = {
  id: Scalars['String'];
};


export type QuerySessionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  filter?: Maybe<SessionFilterSimple>;
  orderBy?: Maybe<SessionOrderByInput>;
};


export type QueryTutoringSettlementsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  filter?: Maybe<TutoringSettlementFilterSimple>;
};


export type QueryMatchRequestArgs = {
  id: Scalars['String'];
};


export type QueryTutoringSettlementRequestsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  filter?: Maybe<TutoringSettlementRequestFilterSimple>;
};


export type QueryShiftsArgs = {
  shiftFilterInput: ShiftFilterInput;
};


export type QuerySessionIssuesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  filter?: Maybe<SessionIssueFilterSimple>;
};


export type QuerySlotStatusesArgs = {
  slotStatusesFilterInput: SlotStatusesFilterInput;
};


export type QueryHomeworksArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  filter?: Maybe<HomeworkFilterSimple>;
};


export type QueryGetLastHomeworkOfStudentArgs = {
  studentId: Scalars['String'];
};


export type QueryGetHomeworkBySessionArgs = {
  sessionId: Scalars['String'];
};


export type QuerySessionSummaryArgs = {
  sessionId: Scalars['String'];
};


export type QuerySessionRequestsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  filter?: Maybe<SessionRequestFilterSimple>;
};


export type QuerySessionRequestArgs = {
  sessionId: Scalars['String'];
};


export type QueryStudentAvailabilityArgs = {
  studentId: Scalars['String'];
};


export type QueryPlansArgs = {
  filter: PlanFilterSimple;
};


export type QuerySubscriptionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  filter?: Maybe<SubscriptionFilterSimple>;
};


export type QueryChargesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  filter?: Maybe<ChargeFilterSimple>;
};


export type QueryConversationArgs = {
  id: Scalars['String'];
};


export type QueryConversationsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  filter?: Maybe<ConversationFilterSimple>;
};


export type QueryCoursesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  filter?: Maybe<CourseFilterSimple>;
};

export type UserFilterSimple = {
  role?: Maybe<Scalars['String']>;
  nameOrEmail?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['Date']>;
  createdBefore?: Maybe<Scalars['Date']>;
};

export type AvailableTutorsToMatchOnSlotInput = {
  utcSlot: Scalars['Float'];
  studentId: Scalars['String'];
};

export type AvailableStartDateForMatchInput = {
  utcSlot: Scalars['Float'];
  tutorId: Scalars['String'];
};

export type SessionFilterSimple = {
  studentId?: Maybe<Scalars['String']>;
  tutorId?: Maybe<Scalars['String']>;
  studentNameOrEmail?: Maybe<Scalars['String']>;
  studentStatuses?: Maybe<Array<StudentStatus>>;
  tutorNameOrEmail?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<SessionStatus>>;
  trial?: Maybe<Scalars['Boolean']>;
  hasOpenIssue?: Maybe<Scalars['Boolean']>;
  hasRescheduleRequest?: Maybe<Scalars['Boolean']>;
  createdAfter?: Maybe<Scalars['Date']>;
  createdBefore?: Maybe<Scalars['Date']>;
};

export type SessionOrderByInput = {
  createdAt?: Maybe<Scalars['String']>;
  durationStart?: Maybe<Scalars['String']>;
};

export type TutoringSettlementFilterSimple = {
  studentId?: Maybe<Scalars['String']>;
  tutorId?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['Date']>;
  createdBefore?: Maybe<Scalars['Date']>;
  studentNameOrEmail?: Maybe<Scalars['String']>;
  tutorNameOrEmail?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<TutoringSettlementStatus>>;
};

export type TutoringSettlementRequestFilterSimple = {
  studentId?: Maybe<Scalars['String']>;
  tutorId?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['Date']>;
  createdBefore?: Maybe<Scalars['Date']>;
  studentNameOrEmail?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<TutoringSettlementRequestStatus>>;
  reasons?: Maybe<Array<TutoringSettlementRequestReason>>;
};

export type ShiftFilterInput = {
  tutorId?: Maybe<Scalars['String']>;
  forTrial?: Maybe<Scalars['Boolean']>;
};

export type SessionIssueFilterSimple = {
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['Date']>;
  createdBefore?: Maybe<Scalars['Date']>;
};

export type SlotStatusesFilterInput = {
  timezone?: Maybe<Scalars['String']>;
  durationStart?: Maybe<Scalars['Date']>;
  calType: Scalars['String'];
  studentId?: Maybe<Scalars['String']>;
};

export type HomeworkFilterSimple = {
  studentId?: Maybe<Scalars['String']>;
  tutorId?: Maybe<Scalars['String']>;
  studentNameOrEmail?: Maybe<Scalars['String']>;
  tutorNameOrEmail?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAfter?: Maybe<Scalars['Date']>;
  createdBefore?: Maybe<Scalars['Date']>;
};

export type SessionRequestFilterSimple = {
  studentId?: Maybe<Scalars['String']>;
  tutorId?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<SessionRequestStatus>>;
  type?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['Date']>;
  createdBefore?: Maybe<Scalars['Date']>;
};

export type PlanFilterSimple = {
  active?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionFilterSimple = {
  planSlug?: Maybe<Scalars['String']>;
  nameOrEmail?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<SubscriptionStatus>>;
  createdAfter?: Maybe<Scalars['Date']>;
  createdBefore?: Maybe<Scalars['Date']>;
};

export type ChargeFilterSimple = {
  nameOrEmail?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['Date']>;
  createdBefore?: Maybe<Scalars['Date']>;
};

export type ConversationFilterSimple = {
  userId?: Maybe<Scalars['String']>;
};

export type CourseFilterSimple = {
  createdAfter?: Maybe<Scalars['Date']>;
  createdBefore?: Maybe<Scalars['Date']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateAvatarUrlAsAdmin: ActionResult;
  setTimezone: AuthResult;
  updateAvatarUrl: AuthResult;
  updateStudentBasics: AuthResult;
  updateTutorBasics: AuthResult;
  updateMessageLastSeen: AuthResult;
  signup: AuthResult;
  updateEmailForConfirmation: AuthResult;
  resendConfirmationLink: AuthResult;
  sendResetPasswordLink: ActionResult;
  resetPassword: ActionResult;
  updatePassword: ActionResult;
  setClaims: AuthResult;
  createStudentAsAdmin: StudentResultOnAdmin;
  updateStudentBasicsAsAdmin: StudentResultOnAdmin;
  createTutor: ActionResult;
  updateTutorBasicsAsAdmin: UpdateTutorAsAdminResult;
  updateTutorPassword: ActionResult;
  markAsCodeorgReady: ActionResult;
  sendOTPForTrial: SendOtpResult;
  verifyOTPForTrial: VerifyOtpResult;
  bookTrial: AuthResult;
  cancelTrial: AuthResult;
  cancelTrialAsTutorOrAdmin: AuthResult;
  cancelSession: AuthResult;
  cancelSessionAsTutorOrAdmin: AuthResult;
  changeSessionTutorAsAdmin: SessionResult;
  createMatch: TutoringSettlementResult;
  requestMatch: RequestMatachResult;
  createShift: CreateShiftResult;
  updateShiftDuration: CreateShiftResult;
  deleteShift: ActionResult;
  reportSessionIssue: SessionIssueResult;
  resolveSessionIssue: SessionIssueResult;
  reportAndResolveSessionIssue: SessionIssueResult;
  createHomework: HomeworkResult;
  updateHomework: HomeworkResult;
  setHomeworkCompleted: HomeworkResult;
  createSessionSummary: SessionSummaryResult;
  updateSessionSummary: SessionSummaryResult;
  createSessionReview: SessionResult;
  updateSessionReview: SessionResult;
  createRescheduleSessionRequest: SessionRequestResult;
  createRegularSessionRequest: SessionResult;
  acceptSessionRequest: SessionResult;
  cancelSessionRequest: SessionResult;
  declineSessionRequest: SessionResult;
  createStudentAvailability: StudentAvailabilityResult;
  updateStudentAvailability: StudentAvailabilityResult;
  prepareSubscription: PrepareSubscriptionResult;
  activateSubscription: SubscriptionResult;
  createSubscription: SubscriptionResult;
  suspendSubscription: SubscriptionResult;
  reactivateSubscription: SubscriptionResult;
  createCharge: ChargeResult;
  markMessageRead: ActionResult;
  sendMessage: SendMessageResult;
  createCourse: CourseResult;
  updateCourse: CourseResult;
  updateNotificationSettings: UpdateNotificationSettingsResult;
};


export type MutationUpdateAvatarUrlAsAdminArgs = {
  updateAvatarUrlAsAdminInput: UpdateAvatarUrlAsAdminInput;
};


export type MutationSetTimezoneArgs = {
  timezone: Scalars['String'];
};


export type MutationUpdateAvatarUrlArgs = {
  updateAvatarUrlInput: UpdateAvatarUrlInput;
};


export type MutationUpdateStudentBasicsArgs = {
  updateStudentBasicsInput: UpdateStudentBasicsInput;
};


export type MutationUpdateTutorBasicsArgs = {
  updateTutorBasicsInput: UpdateTutorBasicsInput;
};


export type MutationUpdateMessageLastSeenArgs = {
  userId: Scalars['String'];
};


export type MutationSignupArgs = {
  signupInput: SignupInput;
};


export type MutationUpdateEmailForConfirmationArgs = {
  email: Scalars['String'];
};


export type MutationSendResetPasswordLinkArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationUpdatePasswordArgs = {
  updatePasswordInput: UpdatePasswordInput;
};


export type MutationSetClaimsArgs = {
  role: Scalars['String'];
  email: Scalars['String'];
};


export type MutationCreateStudentAsAdminArgs = {
  input: CreateStudentAsAdminInput;
};


export type MutationUpdateStudentBasicsAsAdminArgs = {
  input: UpdateStudentBasicsAsAdminInput;
};


export type MutationCreateTutorArgs = {
  createTutorInput: CreateTutorInput;
};


export type MutationUpdateTutorBasicsAsAdminArgs = {
  updateTutorBasicsInput: UpdateTutorBasicsAsAdminInput;
};


export type MutationUpdateTutorPasswordArgs = {
  updateTutorPasswordInput: UpdateTutorPasswordInput;
};


export type MutationVerifyOtpForTrialArgs = {
  input: VerifyOtpInput;
};


export type MutationBookTrialArgs = {
  bookTrialInput: BookTrialInput;
};


export type MutationCancelTrialArgs = {
  cancelTrialInput: CancelTrialInput;
};


export type MutationCancelTrialAsTutorOrAdminArgs = {
  input: CancelTrialAsTutorInput;
};


export type MutationCancelSessionArgs = {
  input: CancelSessionInput;
};


export type MutationCancelSessionAsTutorOrAdminArgs = {
  input: CancelSessionAsTutorInput;
};


export type MutationChangeSessionTutorAsAdminArgs = {
  input: ChangeSessionTutorInput;
};


export type MutationCreateMatchArgs = {
  input: CreateMatchInput;
};


export type MutationRequestMatchArgs = {
  input: RequestMatchInput;
};


export type MutationCreateShiftArgs = {
  createShiftInput: CreateShiftInput;
};


export type MutationUpdateShiftDurationArgs = {
  updateShiftDurationInput: UpdateShiftDurationInput;
};


export type MutationDeleteShiftArgs = {
  deleteShiftInput: DeleteShiftInput;
};


export type MutationReportSessionIssueArgs = {
  input: ReportSessionIssueInput;
};


export type MutationResolveSessionIssueArgs = {
  input: ResolveSessionIssueInput;
};


export type MutationReportAndResolveSessionIssueArgs = {
  input: ReportAndResolveSessionIssueInput;
};


export type MutationCreateHomeworkArgs = {
  createHomeworkInput: CreateHomeworkInput;
};


export type MutationUpdateHomeworkArgs = {
  updateHomeworkInput: UpdateHomeworkInput;
};


export type MutationSetHomeworkCompletedArgs = {
  setHomeworkCompletedInput: SetHomeworkCompletedInput;
};


export type MutationCreateSessionSummaryArgs = {
  createSessionSummaryInput: CreateSessionSummaryInput;
};


export type MutationUpdateSessionSummaryArgs = {
  updateSessionSummaryInput: UpdateSessionSummaryInput;
};


export type MutationCreateSessionReviewArgs = {
  createSessionReviewInput: CreateSessionReviewInput;
};


export type MutationUpdateSessionReviewArgs = {
  updateSessionReviewInput: UpdateSessionReviewInput;
};


export type MutationCreateRescheduleSessionRequestArgs = {
  input: RescheduleSessionRequestInput;
};


export type MutationCreateRegularSessionRequestArgs = {
  input: RegularSessionRequestInput;
};


export type MutationAcceptSessionRequestArgs = {
  input: AcceptSessionRequestInput;
};


export type MutationCancelSessionRequestArgs = {
  input: CancelSessionRequestInput;
};


export type MutationDeclineSessionRequestArgs = {
  input: DeclineSessionRequestInput;
};


export type MutationCreateStudentAvailabilityArgs = {
  input: CreateStudentAvailabilityInput;
};


export type MutationUpdateStudentAvailabilityArgs = {
  input: UpdateStudentAvailabilityInput;
};


export type MutationPrepareSubscriptionArgs = {
  input: PrepareSubscriptionInput;
};


export type MutationActivateSubscriptionArgs = {
  paypalSubId: Scalars['String'];
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationSuspendSubscriptionArgs = {
  input: SuspendSubscriptionInput;
};


export type MutationReactivateSubscriptionArgs = {
  input: ReactivateSubscriptionInput;
};


export type MutationCreateChargeArgs = {
  input: CreateChargeInput;
};


export type MutationMarkMessageReadArgs = {
  markMessageReadInput: MarkMessageReadInput;
};


export type MutationSendMessageArgs = {
  sendMessageInput: SendMessageInput;
};


export type MutationCreateCourseArgs = {
  input: CreateCourseInput;
};


export type MutationUpdateCourseArgs = {
  input: UpdateCourseInput;
};


export type MutationUpdateNotificationSettingsArgs = {
  updateNotificationSettingsInput: Array<UpdateNotificationSettingInput>;
};

export type UpdateAvatarUrlAsAdminInput = {
  userId: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
};

export type UpdateAvatarUrlInput = {
  userId: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
};

export type UpdateStudentBasicsInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  pname: Scalars['String'];
  phone: Scalars['String'];
  dialCode: Scalars['String'];
  timezone: Scalars['String'];
  birthDate: Scalars['Date'];
  gender?: Maybe<Gender>;
};

export type UpdateTutorBasicsInput = {
  zoomUrl: Scalars['String'];
};

export type SignupInput = {
  pname: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  timezone: Scalars['String'];
  dialCode: Scalars['String'];
  phone: Scalars['String'];
  birthDate: Scalars['Date'];
  priorExperience: PriorExperience;
};

export type ResetPasswordInput = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type UpdatePasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type CreateStudentAsAdminInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  pname: Scalars['String'];
  timezone: Scalars['String'];
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  priorExperience: PriorExperience;
  credits: Scalars['Float'];
  status: StudentStatus;
  password: Scalars['String'];
};

export type UpdateStudentBasicsAsAdminInput = {
  studentId: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  pname: Scalars['String'];
  timezone: Scalars['String'];
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
  priorExperience: PriorExperience;
  credits: Scalars['Float'];
  status: StudentStatus;
};

export type CreateTutorInput = {
  name: Scalars['String'];
  gender: Gender;
  email: Scalars['String'];
  paypalEmail?: Maybe<Scalars['String']>;
  skypeId?: Maybe<Scalars['String']>;
  zoomUrl?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  primaryShift: ShiftDivision;
  hourlyRate: Scalars['Float'];
  undergrad: Scalars['Boolean'];
  timezone: Scalars['String'];
};

export type UpdateTutorBasicsAsAdminInput = {
  tutorId: Scalars['String'];
  name: Scalars['String'];
  gender: Gender;
  email: Scalars['String'];
  paypalEmail?: Maybe<Scalars['String']>;
  skypeId?: Maybe<Scalars['String']>;
  zoomUrl?: Maybe<Scalars['String']>;
  primaryShift: ShiftDivision;
  hourlyRate: Scalars['Float'];
  specialAdjustment: Scalars['Float'];
  undergrad: Scalars['Boolean'];
  veteran: Scalars['Boolean'];
  timezone: Scalars['String'];
  status: TutorStatus;
  canOfferTrial: Scalars['Boolean'];
  internalNote?: Maybe<Scalars['String']>;
};

export type UpdateTutorPasswordInput = {
  tutorId: Scalars['String'];
  newPassword: Scalars['String'];
};

export type VerifyOtpInput = {
  verificationId: Scalars['String'];
  code: Scalars['String'];
};

export type BookTrialInput = {
  studentId: Scalars['String'];
  durationStart: Scalars['Date'];
};

export type CancelTrialInput = {
  studentId: Scalars['String'];
  sessionId: Scalars['String'];
};

export type CancelTrialAsTutorInput = {
  sessionId: Scalars['String'];
  reason: Scalars['String'];
  reasonDetails?: Maybe<Scalars['String']>;
  action: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type CancelSessionInput = {
  studentId: Scalars['String'];
  sessionId: Scalars['String'];
};

export type CancelSessionAsTutorInput = {
  sessionId: Scalars['String'];
  reason: Scalars['String'];
  reasonDetails?: Maybe<Scalars['String']>;
  action: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type ChangeSessionTutorInput = {
  tutorId: Scalars['String'];
  sessionId: Scalars['String'];
};

export type CreateMatchInput = {
  slot: Scalars['Float'];
  requestId: Scalars['String'];
  tutorId: Scalars['String'];
  startTime: Scalars['Date'];
};

export type RequestMatchInput = {
  numNeedsToMatch: Scalars['Float'];
};

export type CreateShiftInput = {
  tutorId: Scalars['String'];
  durationStart: Scalars['Date'];
  durationEnd?: Maybe<Scalars['Date']>;
  slots: Array<Scalars['Float']>;
};

export type UpdateShiftDurationInput = {
  id: Scalars['String'];
  tutorId: Scalars['String'];
  durationStart: Scalars['Date'];
  durationEnd?: Maybe<Scalars['Date']>;
};

export type DeleteShiftInput = {
  id: Scalars['String'];
  tutorId: Scalars['String'];
};

export type ReportSessionIssueInput = {
  sessionId: Scalars['String'];
  type: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type ResolveSessionIssueInput = {
  sessionId: Scalars['String'];
  sessionIssueId: Scalars['String'];
  reason: Scalars['String'];
  reasonDetails?: Maybe<Scalars['String']>;
  action: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type ReportAndResolveSessionIssueInput = {
  sessionId: Scalars['String'];
  type: Scalars['String'];
  typeDetails?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  reasonDetails?: Maybe<Scalars['String']>;
  action: Scalars['String'];
  message: Scalars['String'];
};

export type CreateHomeworkInput = {
  sessionId: Scalars['String'];
  dueDate: Scalars['Date'];
  title: Scalars['String'];
  instructions: Scalars['String'];
};

export type UpdateHomeworkInput = {
  id: Scalars['String'];
  dueDate: Scalars['Date'];
  title: Scalars['String'];
  instructions: Scalars['String'];
};

export type SetHomeworkCompletedInput = {
  homeworkId: Scalars['String'];
  messageOnCompletion: Scalars['String'];
};

export type CreateSessionSummaryInput = {
  sessionId: Scalars['String'];
  /** Internal Note */
  coveredPart: Scalars['String'];
  commentGeneral: Scalars['String'];
  coveredTopics: Array<Scalars['String']>;
  publish: Scalars['Boolean'];
};

export type UpdateSessionSummaryInput = {
  id: Scalars['String'];
  coveredPart: Scalars['String'];
  commentGeneral: Scalars['String'];
  coveredTopics: Array<Scalars['String']>;
  publish: Scalars['Boolean'];
};

export type CreateSessionReviewInput = {
  sessionId: Scalars['String'];
  commentOnTutor?: Maybe<Scalars['String']>;
  ratingTotal: Scalars['Float'];
  ratingVideo: Scalars['Float'];
};

export type UpdateSessionReviewInput = {
  id: Scalars['String'];
  commentOnTutor?: Maybe<Scalars['String']>;
  ratingTotal: Scalars['Float'];
  ratingVideo: Scalars['Float'];
};

export type RescheduleSessionRequestInput = {
  sessionId?: Maybe<Scalars['String']>;
  requestMessage?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  durationStart: Scalars['Date'];
  durationEnd: Scalars['Date'];
  reason?: Maybe<Scalars['String']>;
};

export type RegularSessionRequestInput = {
  requestMessage: Scalars['String'];
  tutorId: Scalars['String'];
  dueDate?: Maybe<Scalars['Date']>;
  durationStart: Scalars['Date'];
  durationEnd: Scalars['Date'];
};

export type AcceptSessionRequestInput = {
  id: Scalars['String'];
  responseMessage?: Maybe<Scalars['String']>;
};

export type CancelSessionRequestInput = {
  id: Scalars['String'];
  responseMessage?: Maybe<Scalars['String']>;
};

export type DeclineSessionRequestInput = {
  id: Scalars['String'];
  responseMessage?: Maybe<Scalars['String']>;
};

export type CreateStudentAvailabilityInput = {
  studentId?: Maybe<Scalars['String']>;
  primarySlots: Array<Scalars['Float']>;
  secondarySlots?: Maybe<Array<Scalars['Float']>>;
};

export type UpdateStudentAvailabilityInput = {
  id: Scalars['String'];
  primarySlots: Array<Scalars['Float']>;
  secondarySlots?: Maybe<Array<Scalars['Float']>>;
};

export type PrepareSubscriptionInput = {
  planId: Scalars['String'];
  paypalPlanId: Scalars['String'];
};

export type CreateSubscriptionInput = {
  paypalSubId: Scalars['String'];
  planId: Scalars['String'];
};

export type SuspendSubscriptionInput = {
  subscriptionId: Scalars['String'];
};

export type ReactivateSubscriptionInput = {
  subscriptionId: Scalars['String'];
};

export type CreateChargeInput = {
  paypalId: Scalars['String'];
  paidAt: Scalars['String'];
  amount: Scalars['String'];
  currency: Scalars['String'];
};

export type MarkMessageReadInput = {
  userId: Scalars['String'];
  messageId: Scalars['String'];
};

export type SendMessageInput = {
  conversationId: Scalars['String'];
  senderId: Scalars['String'];
  messageId: Scalars['String'];
  body: Scalars['String'];
  newCursor: Scalars['String'];
};

export type CreateCourseInput = {
  title: Scalars['String'];
  slug: Scalars['String'];
};

export type UpdateCourseInput = {
  courseId: Scalars['String'];
  title: Scalars['String'];
  slug: Scalars['String'];
};

export type UpdateNotificationSettingInput = {
  notificationTypeSlug: Scalars['String'];
  byEmail: Scalars['Boolean'];
  onMobile: Scalars['Boolean'];
  onDesktop: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  messageAdded: MessageAddedResponse;
  onMessageRead: OnMessageReadResponse;
};


export type SubscriptionMessageAddedArgs = {
  userId: Scalars['String'];
};


export type SubscriptionOnMessageReadArgs = {
  userId: Scalars['String'];
  conversationId: Scalars['String'];
};
