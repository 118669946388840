import React from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from '@bugsnag/plugin-react';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as serviceWorker from './serviceWorker';
import client from './apolloClient';
import App from './App';
// import LoadPayPal from './LoadPaypal';
import { AuthProvider } from './auth';
import { GeoProvider } from './geo';

const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId,
};

Bugsnag.start({
  apiKey: '6929b3f7973ec3453ba6ccdd36331f98',
  plugins: [new BugsnagPluginReact()],
});

const BugsnagPlugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult;
const ErrorBoundary = BugsnagPlugin.createErrorBoundary(React);

if (measurementId?.length === 0) {
  delete firebaseConfig.measurementId;
}

firebase.initializeApp(firebaseConfig);

render(
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <AuthProvider>
        <GeoProvider>
          <App />
        </GeoProvider>
      </AuthProvider>
    </ApolloProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
