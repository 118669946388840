import React, { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';

const GeoContext = createContext<{
  timezone: string | null;
  countryCode: string | null;
  fetched: boolean;
}>({
  timezone: null,
  countryCode: null,
  fetched: false,
});

export function GeoProvider({ children }: any) {
  const [timezone, setTimezone] = useState<string | null>(null);
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const [fetched, setFetched] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get('https://get.geojs.io/v1/ip/geo.json')
      .then((res) => {
        setFetched(true);
        setTimezone(res.data.timezone);
        setCountryCode(res.data.country_code);
      })
      .catch(() => {
        setFetched(true);
        setTimezone(moment.tz.guess());
      });
  }, []);

  return (
    <GeoContext.Provider
      value={{
        countryCode,
        timezone,
        fetched,
      }}
    >
      {children}
    </GeoContext.Provider>
  );
}

export const useGeo = () => {
  return useContext(GeoContext);
};
