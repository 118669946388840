import { ApolloClient, HttpLink, split } from '@apollo/client';
import {
  getMainDefinition,
  relayStylePagination,
} from '@apollo/client/utilities';
import { InMemoryCache } from '@apollo/client/cache';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import firebase from 'firebase/app';
import 'firebase/auth';
import moment from 'moment-timezone';
import introspectionResult from './introspection-result';

// FIrebase idToken expires after 1 hour.
// To prevent server to return unauthorized error, force refreshing the token if the token last refreshed more than 55 minutes ago.
const getAuthToken = () => {
  return new Promise((resolve) => {
    const firebaseUser = firebase.auth().currentUser;
    if (firebaseUser) {
      const currentToken = sessionStorage.getItem('idToken');
      let shouldRefresh = true;
      const isoStr = sessionStorage.getItem('idTokenChangedAt');
      if (isoStr) {
        const idTokenChangedAt = moment(isoStr);
        const mins = 55;
        shouldRefresh =
          idTokenChangedAt.add(mins, 'minutes').unix() - moment().unix() <= 0;
      }
      if (!shouldRefresh) {
        return resolve(currentToken);
      }
      return firebaseUser
        .getIdToken(true)
        .then((newToken) => resolve(newToken))
        .catch(() => resolve(null));
    }
    return resolve(null);
  });
};

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        conversations: relayStylePagination(),
      },
    },
  },
});

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT as string;
const WS_ENDPOINT = process.env.REACT_APP_SOCKET_ENDPOINT as string;

const httpLink = new HttpLink({
  uri: API_ENDPOINT,
  credentials: 'include',
});

const wsLink = new WebSocketLink({
  uri: WS_ENDPOINT,
  options: {
    reconnect: true,
    connectionParams: {
      // eslint-disable-next-line no-return-await
      idToken: async () => await getAuthToken(),
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache,
});

export default client;
