import React, { Suspense, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useAuth } from '../../auth';
import AppLoading from '../../components/atoms/AppLoading';

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const TutorConstraintRouteComponent = ({
  component: Component,
  ...rest
}: IProps) => {
  const { isLoggedIn, currentRole } = useAuth();
  const history = useHistory();
  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/login');
    }
    if (isLoggedIn && currentRole && currentRole !== 'tutor') {
      history.push('/', {
        state: {
          flash: {
            message: 'Unauthorized Route',
            type: 'error',
          },
        },
      });
    }
  }, [isLoggedIn, currentRole, history]);
  if (isLoggedIn && !currentRole) {
    return <AppLoading />;
  }
  return (
    <Route
      {...rest}
      render={(renderProps) => {
        return (
          <Suspense fallback={<AppLoading />}>
            <Component {...renderProps} />
          </Suspense>
        );
      }}
    />
  );
};

TutorConstraintRouteComponent.defaultProps = {
  exact: false,
};

const TutorConstraintRoute = React.memo<IProps>(TutorConstraintRouteComponent);

export default TutorConstraintRoute;
