import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GuestConstraintRoute from './GuestConstraintRoute';
import Root from '../pages/Root';
import StudentConstraintRoute from './StudentConstraintRoute';
import AuthConstraintRoute from './AuthConstraintRoute';
import TutorConstraintRoute from './TutorConstraintRoute';

const Signup = React.lazy(() => import('../pages/auth/Signup'));
const ForgotPassword = React.lazy(() => import('../pages/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../pages/auth/ResetPassword'));
const ConfirmEmail = React.lazy(() => import('../pages/auth/ConfirmEmail'));
const Login = React.lazy(() => import('../pages/auth/Login'));
const StudentSettingsAccount = React.lazy(
  () => import('../pages/ForStudents/Settings/Account')
);
const StudentSettingsNotifications = React.lazy(
  () => import('../pages/ForStudents/Settings/Notifications')
);
const StudentSettingsBilling = React.lazy(
  () => import('../pages/ForStudents/Settings/Billing')
);
const TrialCheckout = React.lazy(() => import('../pages/Trial/Checkout/index'));
const BookTrial = React.lazy(() => import('../pages/BookTrial/index'));
const StudentDashboard = React.lazy(
  () => import('../pages/ForStudents/Dashboard')
);
const SessionInfo = React.lazy(() => import('../pages/ForTutors/SessionInfo'));
const SessionSummary = React.lazy(
  () => import('../pages/ForTutors/SessionSummary')
);

const TutorDashboard = React.lazy(() => import('../pages/ForTutors/Dashboard'));
const TutorSettingsAccount = React.lazy(
  () => import('../pages/ForTutors/Settings/Account')
);
const TutorSettingsMyStudents = React.lazy(
  () => import('../pages/ForTutors/Settings/MyStudents')
);

const Messenger = React.lazy(() => import('../pages/Messenger'));

const Lesson = React.lazy(() => import('../pages/Lesson'));

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Root} />
      <AuthConstraintRoute
        path="/confirm-email"
        exact
        component={ConfirmEmail}
      />
      <AuthConstraintRoute
        path="/messages/:conversationId"
        component={Messenger}
      />
      <AuthConstraintRoute path="/messages" exact component={Messenger} />
      <AuthConstraintRoute
        path="/lessons/:lesson_id/:concept_id"
        exact
        component={Lesson}
      />
      <StudentConstraintRoute
        path="/s/dashboard"
        exact
        component={StudentDashboard}
      />
      <StudentConstraintRoute
        path="/s/trial/checkout"
        exact
        component={TrialCheckout}
      />
      <StudentConstraintRoute path="/s/trial" exact component={BookTrial} />
      <StudentConstraintRoute
        path="/s/settings/account"
        exact
        component={StudentSettingsAccount}
      />
      <StudentConstraintRoute
        path="/s/settings/notifications"
        exact
        component={StudentSettingsNotifications}
      />
      <StudentConstraintRoute
        path="/s/settings/billing"
        exact
        component={StudentSettingsBilling}
      />
      <TutorConstraintRoute
        path="/t/dashboard"
        exact
        component={TutorDashboard}
      />
      <TutorConstraintRoute
        path="/t/settings/account"
        exact
        component={TutorSettingsAccount}
      />
      <TutorConstraintRoute
        path="/t/sessions/:id/info"
        exact
        component={SessionInfo}
      />
      <TutorConstraintRoute
        path="/t/sessions/:sessionId/summary/update"
        exact
        component={SessionSummary}
      />
      <TutorConstraintRoute
        path="/t/settings/my-students"
        exact
        component={TutorSettingsMyStudents}
      />
      <GuestConstraintRoute path="/signup" exact component={Signup} />
      <GuestConstraintRoute path="/login" exact component={Login} />
      <GuestConstraintRoute
        path="/forgot-password"
        exact
        component={ForgotPassword}
      />
      <GuestConstraintRoute
        path="/reset-password"
        exact
        component={ResetPassword}
      />
    </Switch>
  );
};

export default Routes;
