import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import AppLoading from '../../../components/atoms/AppLoading';
import { StudentStatus } from '../../../types';

const VIEWER_QUERY = gql`
  query viewer {
    viewer {
      id
      name
      avatarUrl
      email
      timezone
      ... on Student {
        pname
        status
        profile {
          id
          credits
        }
      }
    }
  }
`;

const StudentHandling: React.FC = () => {
  const { data } = useQuery(VIEWER_QUERY);
  const history = useHistory();
  if (data?.viewer !== undefined) {
    const { viewer } = data;
    if (viewer.status === StudentStatus.BeforePayTrial) {
      history.replace('/s/trial/checkout');
    } else if (viewer.status === StudentStatus.BeforeRequestTrial) {
      history.replace('/s/trial');
    } else {
      history.replace('/s/dashboard');
    }
  }
  return <AppLoading />;
};

export default StudentHandling;
