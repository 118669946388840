import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../auth';
import AppLoading from '../../components/atoms/AppLoading';

interface IProps {
  exact?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const AuthConstraintRouteComponent: React.FC<IProps> = ({
  component: Component,
  ...rest
}: IProps) => {
  const { isLoggedIn } = useAuth();

  return (
    <Route
      {...rest}
      render={(renderProps) => {
        if (!isLoggedIn) {
          return <Redirect to="/login" />;
        }
        return (
          <Suspense fallback={<AppLoading />}>
            <Component {...renderProps} />
          </Suspense>
        );
      }}
    />
  );
};

AuthConstraintRouteComponent.defaultProps = {
  exact: false,
};

const AuthConstraintRoute = React.memo<IProps>(AuthConstraintRouteComponent);

export default AuthConstraintRoute;
