import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AppLoading from '../../components/atoms/AppLoading';
import { useAuth } from '../../auth';
import StudentHandling from './StudentHandling';

const Root = () => {
  const history = useHistory();
  const { isLoggedIn, currentRole } = useAuth();
  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/login');
    }
    if (isLoggedIn && currentRole) {
      if (currentRole === 'tutor') {
        history.replace('/t/dashboard');
      }
    }
  }, [isLoggedIn, currentRole, history]);
  if (isLoggedIn && currentRole === 'student') {
    return <StudentHandling />;
  }
  return <AppLoading />;
};

export default Root;
